/* You can add global styles to this file, and also import other style files */
@import "primeicons/primeicons.css";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
@font-face {
    font-family: 'Labora';
    src: url('./app/fonts/Labora-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'oblong';
    src: url('./app/fonts/oblong.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'blackNote';
    src: url('./app/fonts/BlackNoteRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'backy';
    src: url('./app/fonts/BackyRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'prestick';
    src: url('./app/fonts/PrestickRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'tawes';
    src: url('./app/fonts/TawesRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'synonym';
    src: url('./app/fonts/Synonym.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

body {
    background: white;
    overscroll-behavior: none;
}

:host ::ng-deep {
    .p-inputtext {
        padding: 0.5rem 0.75rem !important;
    }

    .p-button {
        height: 35px;
        border-radius: 6px;
        padding: 0.75rem 1.25rem;
        font-size: 1rem;
    }

    .p-button-label {
        font-weight: 400;
    }

    .tabMenu {
        .p-menuitem-link {
            text-decoration: none !important;
            color: #6b7280 !important;
            font-size: 0.85rem !important;
        }

        .p-tabmenuitem.p-highlight .p-menuitem-link {
            color: #3B82F6 !important;
            font-size: 0.85rem !important;
        }
    }

    .searchField {
        .p-inputtext {
            padding-left: 2.5rem !important;/* Adjust the value as needed */
        }

        .p-inputtext::placeholder { /* Target the placeholder directly */
            transition: transform 0.3s ease-in-out; /* Add transition */
        }
    }

    .p-button-link {
        padding: 0;
    }

    ul {
        padding-left: 0 !important;
        margin-bottom: 0 !important;
    }

    .p-dropdown-panel .p-dropdown-items {
        padding-left: 0 !important;
    }

    .p-multiselect {
        width: 100%;
    }
}

// .mainContainer {
//     padding: 0 4rem;
// }